// import libraries
import * as ko from 'knockout';
import 'knockout.validation'
import i18nextko from './bindings/i18nko'
import {Context, Router} from '@profiscience/knockout-contrib-router';
import 'bootstrap';
import 'knockstrap';
import globalState from './global-state';
import scrollToError from "./bindings/scrollToError";
import enterkey from "./bindings/enterkey";
import blur from "./bindings/blur";
import {postbox} from "./components/util/postbox";
import {appApi, auth0, notificationApi, userApi} from "./api/api-wrapper";
import "./bindings/knockout-date-bindings";
import "./bindings/knockout-select2";
import "./bindings/knockout-date-picker";
import "./bindings/knockout-foreach-groups";
import "./bindings/knockout-gallery";
import "./bindings/knockout-linkify";
import "./bindings/knockout-checked-inverse";
import 'knockout-file-bindings';
import 'knockout.autocomplete/lib/knockout.autocomplete';
import 'knockout.autocomplete/lib/knockout.autocomplete.css';
import "./components/comment/comment"
import "./components/rating/rating"
import "./pages/evaluation/criteria"
import "./components/elements/user/admin-contact"
import "./components/elements/user/user-image"
import "./components/elements/idea/idea-list-item"
import "./components/elements/user/user-list-item"
import "./components/elements/attachments/documents"
import "./components/elements/attachments/images"
import "./components/elements/attachments/videos"
import "./components/widgets/text-length-indicator"
import {UserDto} from "./api/generated";
import {computed} from "knockout-decorators";
import '../loader.html';
import {auth0RedirectLoginOptions} from "./utils/auth0Options";
import {config} from "./utils/clientConfigWrapper";
import moment = require("moment");

/**
 * The root view model of the application.
 */
class AppViewModel {

    /**
     * Shows/Hides the loading indicator
     */
    public loading = globalState.loading;

    /**
     * Shows/Hides the backdrop for the tutorial.
     */
    public tutorialEnabled = globalState.tutorialEnabled;

    /**
     * Triggers scrollToError binding.
     */
    public hasErrors: KnockoutComputed<boolean>;

    public authenticatedUser: KnockoutObservable<UserDto> = globalState.user;

    /**
     * Access the sendEmail state in views.
     */
    public sendMail: KnockoutObservable<boolean>;

    constructor() {
        /**
         * Scroll to error if postbox has errors or globalState.hasErrors (to trigger the scrolling manually)
         */
        this.hasErrors = ko.pureComputed(() => {
            return postbox.errors().length > 0 || globalState.hasErrors();
        }, this).extend({rateLimit: 500});

        this.sendMail = globalState.sendMail;
    }

    /**
     * Toggle the flag to send mails.
     */
    public toggleSendMail() {
        globalState.sendMail(!globalState.sendMail());
    }

    /**
     * Translates a key.
     *
     * @param key - see https://www.i18next.com/overview/api#t
     * @param options - see https://www.i18next.com/translation-function/interpolation
     */
    public i18n(key: String, options?: object) {
        return i18nextko.t(key, options);
    }

    @computed
    public get notifications() {
        return globalState.notifications;
    }

    @computed
    public get notificationsUnread() {
        const lastRead = globalState.user().notificationsRead ?
            moment(globalState.user().notificationsRead) : null;
        if (lastRead) {
            return globalState.notifications.filter(notification =>
                moment(notification.created).isAfter(lastRead));
        }
        return globalState.notifications;
    }

    @computed
    public get isAdmin() {
        const user = this.authenticatedUser();
        return user && user.admin;
    }

    @computed
    public get isLoggedIn() {
        const user = this.authenticatedUser();
        return user && user.registrationCompleted;
    }

    public username(userDto: UserDto | KnockoutObservable<UserDto>): string {
        return App.username(userDto);
    }

    public bonusEnabled() {
        return config.bonusEnabled;
    }

    public userRatingsEnabled() {
        return config.userRatingsEnabled;
    }

    public get certificateEnabled() {
        return config.certificateEnabled;
    }

    public get implementationDecisionRequired() {
        return config.implementationDecisionRequired;
    }

    public provider() {
        return config.auth0Options.provider;
    }
}

/**
 * The application controller.
 */
export class App {

    /**
     * The root view model instance.
     */
    public viewModel: AppViewModel;

    /**
     * Initializes the root view model.
     */
    constructor() {
        this.viewModel = new AppViewModel();
    }

    /**
     * Initializes the app:
     * - registers custom events: headerInitialized
     * - sets up the ko-component-router for navigation between pages
     * - registers loading and scrollTop router middleware
     * - registers additional binding handlers: scrollToError
     * - registers custom components: notifications, text-input
     * - loads translations: i18next
     * - initializes ko validation
     * - applies bindings to root view model
     */
    init(): Promise<void> {
        return auth0.handleRedirectCallback().then(
            redirectLoginResult => {
                console.debug("app: successfully logged in");

                // restore returnTo appState, if set
                history.replaceState({}, '',
                    redirectLoginResult.appState && redirectLoginResult.appState.returnTo ?
                        redirectLoginResult.appState.returnTo : window.location.href
                );

                return userApi.getAuthenticatedUser({credentials: 'same-origin'})
                    .then(user => {
                        globalState.user(user);
                        globalState.tutorialEnabled(!user.tutorialDismissed);

                        return user;
                    })
                    .then(() => this._init());
            },
            err => {
                if (err.error === 'unauthorized' && err.message === 'Email not verified') {
                    // logged in, but email not verified yet
                    console.debug('app: email not verified', err);

                    return this._init();

                } else {
                    // not logged in
                    const options = Object.assign({}, auth0RedirectLoginOptions,
                        {
                            appState: {
                                returnTo: window.location.pathname
                            }
                        });

                    return auth0.loginWithRedirect(options);
                }
            });
    }

    private _init(): Promise<void> {
        moment.locale('de');

        console.debug('app: router base', '/' + window.clientConfig.publicPath || '');
        //setup router
        Router.setConfig({
            base: '/' + window.clientConfig.publicPath || ''
        });

        Router.useRoutes({
            '/': [App.loadComponent('home/home.ts')],
            '/tutorial': [App.enableTutorial, App.loadComponent('home/home.ts')],
            '/idee': [App.loadComponent('ideas/ideas.ts')],
            '/idee/neu': [App.loadComponent('idea/create.ts')],
            '/idee/neu/:campaignId': [App.loadComponent('idea/create.ts')],
            '/idee/:id/neu': [App.loadComponent('idea/create.ts')],
            '/idee/:id/bearbeiten': [App.loadComponent('idea/edit.ts')],
            '/idee/:id': [App.loadComponent('idea/idea.ts')],
            '/gutachten/:id/erstellen': [App.loadComponent('evaluation/evaluate.ts')],
            '/gutachten/:id/umsetzungsbericht': [App.loadComponent('evaluation/implementation.ts')],
            '/gutachten/:id/bewerten': [App.loadComponent('evaluation/assessment.ts')],
            '/gutachten/:id/bearbeiten': [App.adminCheck, App.loadComponent('evaluation/edit.ts')],
            '/gutachten/:id': [App.loadComponent('evaluation/evaluation.ts')],
            '/kampagne': [App.loadComponent('campaigns/campaignList.ts')],
            '/kampagne/neu': [App.adminCheck, App.loadComponent('campaigns/campaign.ts')],
            '/kampagne/:id': [App.loadComponent('campaigns/campaign.ts')],
            '/community': [App.loadComponent('community/community.ts')],
            '/info': [App.loadComponent('infos/infos.ts')],
            '/news': [App.loadComponent('news/newsList.ts')],
            '/news/neu': [App.adminCheck, App.loadComponent('news/news.ts')],
            '/news/:id': [App.loadComponent('news/news.ts')],
            '/profile/:id': [App.notificationsReadMiddleware, App.loadComponent('user/profile.ts')],
            '/profile/:id/vervollstaendigen': [App.loadComponent('user/complete.ts')],
            '/profile/:id/bearbeiten': [App.loadComponent('user/edit.ts')],
            '/search': [App.loadComponent('search/search.ts')],
            '/admin': [App.adminCheck,
                App.loadComponent('admin/menu.ts'), {
                    '/dashboard': [App.loadComponent('admin/dashboard.ts')],
                    '/idee/wiederherstellen': [App.loadComponent('admin/deletedIdeas.ts')],
                    '/reports': [App.loadComponent('admin/reports.ts')],
                    '/reports/ideas-overview': [App.loadComponent('admin/reports/ideasOverview.ts')],
                    '/reports/ideas-state': [App.loadComponent('admin/reports/ideasState.ts')],
                    '/reports/ideas-active': [App.loadComponent('admin/reports/ideasActive.ts')],
                    '/reports/ideas-implemented': [App.loadComponent('admin/reports/ideasImplemented.ts')],
                    '/reports/ideas-department': [App.loadComponent('admin/reports/ideasPerDepartment.ts')],
                    '/reports/ideas-category': [App.loadComponent('admin/reports/ideasPerCategory.ts')],
                    '/reports/ideas-likes': [App.loadComponent('admin/reports/ideasLikes.ts')],
                    '/reports/ideas-comments': [App.loadComponent('admin/reports/ideasComments.ts')],
                    '/reports/ideas-submitter': [App.loadComponent('admin/reports/ideasSubmitter.ts')],
                    '/reports/ideas-score': [App.loadComponent('admin/reports/ideasScore.ts')],
                    '/reports/ideas-state-durations': [App.loadComponent('admin/reports/ideasStatesDuration.ts')],
                    '/reports/evaluation-periods': [App.loadComponent('admin/reports/evaluationPeriods.ts')],
                    '/reports/evaluations-overview': [App.loadComponent('admin/reports/evaluationsOverview.ts')],
                    '/reports/evaluators': [App.loadComponent('admin/reports/evaluators.ts')],
                    '/reports/bonus-overview': [App.loadComponent('admin/reports/bonusOverview.ts')],
                    '/reports/user-comments': [App.loadComponent('admin/reports/userComments.ts')],
                    '/reports/user-awards': [App.loadComponent('admin/reports/userAwards.ts')],
                    '/reports/feedbacks': [App.loadComponent('admin/reports/feedbacks.ts')],
                    '/reports/community-overview': [App.loadComponent('admin/reports/communityOverview.ts')],
                    '/reports/activities-overview': [App.loadComponent('admin/reports/activitiesOverview.ts')],
                    '/reports/activities-counts': [App.loadComponent('admin/reports/activitiesCounts.ts')],
                    '/reports/users-overview': [App.loadComponent('admin/reports/usersOverview.ts')],
                    '/categories': [App.loadComponent('admin/categories.ts')]
                }]
        });

        Router.use(App.loadAppVars)
        Router.use(App.clearNotificationsMiddleware);
        Router.use(App.loadingMiddleware);
        Router.use(App.scrollTopMiddlware);
        Router.use(App.anchorLinksMiddlware);
        Router.use(App.hideResponsiveMenuMiddleware);
        Router.use(App.registrationCompletedCheck);

        // register bindings
        ko.bindingHandlers.scrollToError = scrollToError;
        ko.bindingHandlers.enterkey = enterkey;
        ko.bindingHandlers.blur = blur;

        // register components
        ko.components.register('text-input',
            require('./components/widgets/text-input').default);
        ko.components.register('text-area',
            require('./components/widgets/text-area').default);
        ko.components.register('notifications',
            require('./components/notifications/notifications').default);

        // register validation extenders
        ko.validation.rules['confirmed'] = {
            validator: function (val, expected) {
                return val === expected;
            },
            message: 'The field must by checked.'
        };
        ko.validation.registerExtenders();

        // load notifications initially and periodically
        window.setInterval(() => {
            notificationApi.getUserNotifications().then(notifications =>
                globalState.notifications = notifications);
        }, window.clientConfig.notificationsCheckInterval);

        // localizations and validations
        return this.loadTranslations()
            .then(() => {
                // initialize validation
                ko.validation.init({
                    insertMessages: false,
                    grouping: {
                        deep: true,
                        live: true,
                        observable: true
                    },
                    messagesOnModified: true
                }, true);
                ko.validation.localize(i18nextko.t('validation', {returnObjects: true})());

                ko.applyBindings(this.viewModel, document.body);
            });
    }

    /**
     * Loads and registers (if not done before) a component. Used be the router to load pages.
     * @param componentName the component name
     * @returns Promise<void> a Promise
     */
    static loadComponent(componentName: string) {
        return (ctx: Context) => {
            console.debug("app: loading component " + componentName);

            // reseting page title
            document.title = 'Ideenwerkstatt';

            return import("./pages/" + componentName)
                .then(
                    (loadedComponent) => {
                        let loadedComponentDefinition = <KnockoutLazyPageDefinition>loadedComponent.default;
                        console.debug("app: loaded component " + componentName);

                        if (!ko.components.isRegistered(loadedComponentDefinition.componentName)) {
                            ko.components.register(loadedComponentDefinition.componentName, loadedComponentDefinition);
                            console.debug("app: registered component " + loadedComponentDefinition.componentName);
                        }

                        let loaderPromise;
                        if (loadedComponentDefinition.loader) {
                            loaderPromise = loadedComponentDefinition.loader(ctx);

                        } else {
                            loaderPromise = Promise.resolve();
                        }

                        return loaderPromise.then(() => {
                            console.debug("app: setting ctx.router.component to component " +
                                loadedComponentDefinition.componentName);
                            ctx.route.component = loadedComponentDefinition.componentName;

                            return Promise.resolve();
                        });
                    }
                ).catch((error) => {
                    console.error("app: load failed", error);
                    globalState.loading(false);

                    if (error.status && (error.status == 404 || error.status == 500)) {
                        postbox.addError(`load.status.${error.status}`, {error: error}, error);

                    } else {
                        if (error.error === 'unauthorized' && error.message === 'Email not verified') {
                            postbox.addError('global.error.emailUnverified', null, error);

                        } else if (error.error === 'login_required' && error.message === 'Login required') {
                            postbox.addError('global.error.loginRequired', null, error);

                        } else {
                            postbox.addError("load.failed", {error: error}, error);
                        }
                    }

                    return Promise.resolve();
                });
        };
    }

    static enableTutorial() {
        globalState.tutorialEnabled(true);

        return Promise.resolve();
    }

    static notificationsReadMiddleware(ctx) {
        return {
            afterDispose() {
                const userId: number = ctx.params.id ? Number(ctx.params.id) : null;
                const authenticatedUserId: number = globalState.user().id || null;
                // only if user leaves his own profile page
                if (userId && authenticatedUserId && userId === authenticatedUserId) {
                    userApi.putNotificationsRead(authenticatedUserId).then(readDate => {
                        const user = globalState.user();
                        user.notificationsRead = readDate;
                        // Set the user to notify computed observables
                        globalState.user(user);
                    })
                }
            }
        }
    }


    static adminCheck() {
        if (!globalState.user().admin) {
            Router.update("/idee", {
                force: false,
                push: true
            });
            return Promise.reject("Forbidden");
        }
        return Promise.resolve();
    }

    static authenticatedUser(): Promise<UserDto> {
        if (!globalState.user()) {
            return userApi.getAuthenticatedUser({credentials: 'same-origin'})
                .then(user => {
                    globalState.user(user);
                    globalState.tutorialEnabled(!user.tutorialDismissed);

                    return user;
                });

        } else {
            return Promise.resolve(globalState.user());
        }
    }

    /**
     * Get the users display name.
     *
     * @param userDto
     */
    static username(userDto: UserDto | KnockoutObservable<UserDto>): string {
        const user = ko.isObservable(userDto) ? userDto() : userDto;
        if ((user.prename && user.prename.trim().length > 0) ||
            (user.surname && user.surname.trim().length > 0)) {
            const prename = user.prename ? user.prename.trim() + ' ' : '';
            const surname = user.surname ? user.surname.trim() : '';

            return prename + surname;
        }

        return user.email ? user.email.trim() : '';
    }

    /**
     * Page loading indicator router middleware.
     */
    static loadingMiddleware() {

        return {
            beforeRender() {
                globalState.loading(true);
            },
            afterRender() {
                globalState.loading(false);
            }
        };
    }

    /**
     * Scroll to top after page load middleware
     */
    static scrollTopMiddlware() {

        return {
            beforeRender() {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto'
                });
            }
        };
    }

    /**
     * Scroll to anchor links after page load middleware.
     */
    static anchorLinksMiddlware() {
        return {
            afterRender() {
                const hash = (location.pathname + location.hash)
                    .replace(/#!/, '')
                    .replace(/^[^#]+#?/, '');
                if (hash) {
                    const anchor = document.getElementById(hash);
                    if (anchor !== null) {
                        const y = anchor.offsetTop;
                        window.scrollTo(0, y)
                    } else {
                        console.warn('app: anchorLinksMiddlware:',
                            `Navigated to page with #${hash}, but no element with id ${hash} found.`);
                    }
                }
            }
        };
    }

    /**
     * Removes error notifications on page change.
     */
    static clearNotificationsMiddleware() {

        return {
            beforeRender() {
                postbox.clearErrors();
            }
        }
    }

    /**
     * Hide the responsive menu if it's opened.
     */
    static hideResponsiveMenuMiddleware() {
        return {
            afterRender() {
                const toogle = document.getElementById("navbar-toggler");
                const attrExpanded = toogle ? toogle.getAttribute('aria-expanded') : false;
                if (attrExpanded && attrExpanded === 'true') {
                    toogle.click();
                }
            }
        }
    }

    /**
     * Redirect the user to the registration / profile completion page if the registration is not completed.
     * Do not redirect if he is already on the registration completion page.
     */
    static registrationCompletedCheck() {
        return {
            beforeRender() {
                const user = globalState.user();
                const currentPath = Router.getPathFromLocation();

                // Do not redirect on profile completion page
                if (currentPath.startsWith('/profile') && currentPath.indexOf("/vervollstaendigen") > -1) {
                    return Promise.resolve();

                } else if (user && !user.registrationCompleted) {
                    return Router.update(`/profile/${user.id}/vervollstaendigen`, {
                        push: false,
                        force: true
                    }).then(() => Promise.reject("Profile not completed"));
                }

                return Promise.resolve();
            }
        }
    }

    /**
     * Loads application variables set by admin.
     */
    static loadAppVars() {
        if (!globalState.user()) {
            // user not logged in, do not load app vars
            return Promise.resolve();
        } else if (globalState.appVars) {
            // app vars alraedy loaded for logged in user
            return Promise.resolve();
        } else {
            // app vars for userhave not been loaded yet
            return appApi.getAppVars()
                .then(appVars => {
                    console.debug("app: loaded app vars:", appVars);
                    globalState.appVars = appVars;

                    return true;
                });
        }
    }

    /**
     * Initialize translations.
     * @returns Promise<void> a promise when the translations have been laoded
     */
    loadTranslations() {
        const language = config.locale;
        const english = require('../l10n/en.json');

        return import('../l10n/' + language + '.json')
            .catch(
                (err) => {
                    console.error('app: loading translations failed', err);
                    let resourceStore = {
                        en: english
                    };
                    i18nextko.init({
                        compatibilityAPI: 'v3',
                        lng: language || 'en',
                        fallbackLng: 'en',
                        resources: resourceStore
                    });
                }
            )
            .then(
                (targetLanguage) => {
                    let resourceStore = {
                        en: english
                    };
                    resourceStore[language] = targetLanguage;
                    i18nextko.init({
                        compatibilityAPI: 'v3',
                        lng: language || 'en',
                        fallbackLng: 'en',
                        resources: resourceStore
                    });
                }
            );
    }

    /**
     * Get enum values as option list.
     *
     * @param theEnum
     * @param i18nPrefix
     */
    static enumOptions(theEnum, i18nPrefix) {
        return Object.keys(theEnum)
            .filter((key, idx) => idx % 2 != 0)
            .filter(key => key.toLowerCase() != "unknown")
            .map(key => ({
                text: i18nextko.t(i18nPrefix + key.toLowerCase()),
                value: key
            }));
    }

    /**
     * Get object values as option list.
     *
     * @param theEnum
     * @param i18nPrefix
     */
    static objectOptions(obj) {
        if (!obj) {
            return [];
        }
        return Object.keys(obj)
            .map(key => ({
                text: obj[key].text,
                value: key,
                order: obj[key].order
            }))
            .sort((a, b) => a.order - b.order);
    }
}
